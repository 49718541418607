
<template>
  <div class="content">
    <!-- search bar -->
    <div class="row my-3">
      <div class="col-8"></div>
      <div class="col-4">
        <!-- Header Search Input -->
        <a-input-search class="header-search" :class="searchLoading ? 'loading' : ''" placeholder="Search by BusinessUnit#, Location#, Operator#, City, State, FirstName, LastName…"
          @search="onSearch" :loading='searchLoading' v-model="searchQuery">
          <svg slot="prefix" width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 9.29583 13.5892 10.4957 12.8907 11.4765L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L11.4765 12.8907C10.4957 13.5892 9.29583 14 8 14C4.68629 14 2 11.3137 2 8Z"
              fill="#111827" />
          </svg>
        </a-input-search>
        <!-- / Header Search Input -->
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card>
          <template slot="header">
            <div class="row justify-content-between align-items-between mx-3">

              <h5 class="card-title">Artifacts</h5>
              
              <div>
                  <base-button class="btn btn-primary" @click="modalArtifacts = true">Add</base-button>
	              <base-button class="btn btn-primary" @click="toggleView">Toggle View</base-button>
              </div>
              
              <modal :show.sync="modalArtifacts">
                <template slot="header">
                  <h5 class="modal-title" id="exampleModalLabel">Add Artifact</h5>
                </template>
                <div>
                  <form @submit.prevent>
  <base-input label="ArtifactId" type="text" placeholder="Enter ArtifactId" v-model="artifactToAdd.artifactId"></base-input>
                  </form>
                </div>
                <template slot="footer">
                  <base-button type="primary" @click="handleAddSubmitted()">Save</base-button>
                </template>
              </modal>
            </div>
          </template>


          <!-- Conditionally render the view based on the 'isTableView' flag -->
          <div v-if="isTableView">
            <!-- Render the existing Table View -->
            <a-table :columns="columns" :data-source="artifacts" :row-key="record => record.ArtifactId" :pagination="pagination"
              :loading="searchLoading" @change="onTableChange" :scroll="{ x: 'max-content' }">

              <template slot="artifactId" slot-scope="dataIndex">
              	<router-link  :to="`/artifact/${dataIndex}`"><a >{{ dataIndex }}</a></router-link>
              </template>


             <template slot="lastModified" slot-scope="dataIndex">
              	{{ formatTime(dataIndex) }}
              </template>
              <template slot="createdOn" slot-scope="dataIndex">
              	{{ formatTime(dataIndex) }}
              </template>
              <template slot="blackOutStartDate" slot-scope="dataIndex">
              	{{ formatDate(dataIndex) }}
              </template>
            </a-table>
          </div>
          <div v-else>
            <!-- Render the Picture View  -->
            <ArtifactPictureView :artifacts="artifacts" />
          </div>

        </card>
      </div>
    </div>

  </div>
</template>

<script>
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/Inputs/BaseInput";
import NotificationTemplate from "@/pages/Notifications/NotificationTemplate";
import { Card } from "@/components/index";
import ArtifactService from "../services/ArtifactService";
import { ASelect, ASelectOption, AButton, Table, Pagination } from "ant-design-vue";
import ArtifactPictureView from './ArtifactPictureView.vue';


const artifactsColumns = [
  "artifactId",
  "year",
  "date",
  "competitionId",
  "artifactId"
]

export default {
  props: {
    artifacts: {
      type: Array,
      required: true,
    },
    totalElements: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
  },
  components: {
    Card,
    Modal,
    BaseButton,
    BaseInput,
    Table,

    Pagination,
    ArtifactPictureView
  },

  data() {
    return {
      modalArtifacts: false,
        isTableView: false,

      columns: [
        {
          title: 'Artifact ID',
				dataIndex: 'artifactId',
	          visible: false,
          scopedSlots: { customRender: 'artifactId' },
          sorter: true
          //sorter: (a, b) => a.artifactId - b.artifactId,
          //sorter: (a, b) => a.artifactId.localeCompare(b.artifactId),
        },
        {
          title: 'Image File',
		dataIndex: 'imageFile',
          visible: true,
          scopedSlots: { customRender: 'imageFile' },
          sorter: true
          //sorter: (a, b) => a.imageFile - b.imageFile,
          //sorter: (a, b) => a.imageFile.localeCompare(b.imageFile),
        },
        {
          title: 'City, State',
		dataIndex: 'cityState',
          visible: true,
          scopedSlots: { customRender: 'cityState' },
          sorter: true
          //sorter: (a, b) => a.cityState - b.cityState,
          //sorter: (a, b) => a.cityState.localeCompare(b.cityState),
        },
        {
          title: 'Given Name',
		dataIndex: 'givenName',
          visible: true,
          scopedSlots: { customRender: 'givenName' },
          sorter: true
          //sorter: (a, b) => a.givenName - b.givenName,
          //sorter: (a, b) => a.givenName.localeCompare(b.givenName),
        },
        {
          title: 'Middle Name',
		dataIndex: 'middleName',
          visible: true,
          scopedSlots: { customRender: 'middleName' },
          sorter: true
          //sorter: (a, b) => a.middleName - b.middleName,
          //sorter: (a, b) => a.middleName.localeCompare(b.middleName),
        },
        {
          title: 'Surname',
		dataIndex: 'surname',
          visible: true,
          scopedSlots: { customRender: 'surname' },
          sorter: true
          //sorter: (a, b) => a.surname - b.surname,
          //sorter: (a, b) => a.surname.localeCompare(b.surname),
        },
        {
          title: 'Maiden Name',
		dataIndex: 'maidenName',
          visible: true,
          scopedSlots: { customRender: 'maidenName' },
          sorter: true
          //sorter: (a, b) => a.maidenName - b.maidenName,
          //sorter: (a, b) => a.maidenName.localeCompare(b.maidenName),
        },
        {
          title: 'Surname Variation',
		dataIndex: 'surnameVariation',
          visible: true,
          scopedSlots: { customRender: 'surnameVariation' },
          sorter: true
          //sorter: (a, b) => a.surnameVariation - b.surnameVariation,
          //sorter: (a, b) => a.surnameVariation.localeCompare(b.surnameVariation),
        },
        {
          title: 'Birth Date',
		dataIndex: 'birthDate',
          visible: true,
          scopedSlots: { customRender: 'birthDate' },
          sorter: true
          //sorter: (a, b) => a.birthDate - b.birthDate,
          //sorter: (a, b) => a.birthDate.localeCompare(b.birthDate),
        },
        {
          title: 'Death Date',
		dataIndex: 'deathDate',
          visible: true,
          scopedSlots: { customRender: 'deathDate' },
          sorter: true
          //sorter: (a, b) => a.deathDate - b.deathDate,
          //sorter: (a, b) => a.deathDate.localeCompare(b.deathDate),
        },
        {
          title: 'Photo Date',
		dataIndex: 'photoDate',
          visible: true,
          scopedSlots: { customRender: 'photoDate' },
          sorter: true
          //sorter: (a, b) => a.photoDate - b.photoDate,
          //sorter: (a, b) => a.photoDate.localeCompare(b.photoDate),
        },
        {
          title: 'Marriage Date',
		dataIndex: 'marriageDate',
          visible: true,
          scopedSlots: { customRender: 'marriageDate' },
          sorter: true
          //sorter: (a, b) => a.marriageDate - b.marriageDate,
          //sorter: (a, b) => a.marriageDate.localeCompare(b.marriageDate),
        },
        {
          title: 'Location Found',
		dataIndex: 'locationFound',
          visible: true,
          scopedSlots: { customRender: 'locationFound' },
          sorter: true
          //sorter: (a, b) => a.locationFound - b.locationFound,
          //sorter: (a, b) => a.locationFound.localeCompare(b.locationFound),
        },
        {
          title: 'City Photograph Taken',
		dataIndex: 'cityPhotographTaken',
          visible: true,
          scopedSlots: { customRender: 'cityPhotographTaken' },
          sorter: true
          //sorter: (a, b) => a.cityPhotographTaken - b.cityPhotographTaken,
          //sorter: (a, b) => a.cityPhotographTaken.localeCompare(b.cityPhotographTaken),
        },
        {
          title: 'Photographer Or Studio Mark',
		dataIndex: 'photographerOrStudioMark',
          visible: true,
          scopedSlots: { customRender: 'photographerOrStudioMark' },
          sorter: true
          //sorter: (a, b) => a.photographerOrStudioMark - b.photographerOrStudioMark,
          //sorter: (a, b) => a.photographerOrStudioMark.localeCompare(b.photographerOrStudioMark),
        },
      ],
      pagination: {
        current: 1,
        pageSize: 50,
        total: 0,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total) => `Total ${total} artifacts`,
      },

      artifacts: [],
      artifactToAdd: {},

      artifactsTable: {
        columns: [...artifactsColumns],
        data: [],
      },

      // New properties for sorting and searching
      sortBy: 'artifactId',           // Column to sort by
      sortOrder: 'asc',     // Sort order (asc or desc)
      searchQuery: '',      // Search query
      searchLoading: false, // Initialize searchLoading property


    };
  },
  watch: {
    searchQuery: {
      handler: "handleSearchQueryChanged", // Call the fetchData method when searchQuery changes
      immediate: true, // Trigger immediately when the component is mounted
    },
  },

  methods: {

    async renderArtifactsTable() {
      this.searchLoading = true; // Set searchLoading to true while fetching data
      this.searchLoading = false;

      this.pagination.total = this.totalElements;
      this.pagination.current = this.page;

      let artifactsTableData = [];
      for (let i = 0; i < this.artifacts.length; i++) {
        artifactsTableData.push({
          id: i,
          artifactId: this.artifacts[i].artifactId,
          year: this.artifacts[i].year,
          date: this.artifacts[i].date,
          competitionId: this.artifacts[i].competitionId,
          artifactId: this.artifacts[i].artifactId,
        });

      }
      this.searchLoading = false;
    },

    async onTableChange(pagination, filters, sorter) {
      if (sorter && sorter.field && sorter.order) {
        this.sortBy = sorter.field;
        if (sorter.order == "ascend") {
            this.sortOrder = "asc";
        } else {
            this.sortOrder = "desc";
        }
      }
      if (pagination) {
        this.pagination.current = pagination.current;
        this.pagination.pageSize = pagination.pageSize;
      }

	  this.$emit('get-all-artifacts',this.sortBy,this.sortOrder,this.pagination.current-1,this.pagination.pageSize);
      this.handleTableChanged()
    },
	
	initializeColumns() {
        this.columns = this.columns.filter(item => item.visible);
    },

    routingToArtifactDetail(id) {
      this.$router.push({ name: 'ArtifactDetail', params: { artifactId: id.toString() }})
    },
    
    handleSearchQueryChanged() {
    	console.log("handleSearchQueryChanged CALLED FROM @search")
    	this.$root.$emit('searchQueryForArtifactsChanged', this.searchQuery);
		//this.renderArtifactsTable();
    },

    onSearch(value) {
      console.log(value);
      this.searchQuery = value; // Update searchQuery when the user types
    },

    toggleView() {
      this.isTableView = !this.isTableView;
    },
	
	async handleAddSubmitted() {
      this.modalArtifacts = false;

      const currentDate = new Date().getTime();
      this.artifactToAdd.created = currentDate;

      const jsonData = JSON.stringify(this.artifactToAdd);
      console.log(jsonData);
      
      const res = await ArtifactService.addArtifact(jsonData);

      if (res.status === 200) {
        this.$notify({
          component: NotificationTemplate,
          icon: "tim-icons icon-bell-55",
          type: "success",
          timeout: 3000,
        });
      }
	},
	
	handleTableChanged() {
	},
	
	formatTime(dateString) {
      if(dateString !== null){
        const date = new Date(dateString);
      return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} EST`;
      }
      // Format the date here as needed, for example:
    },  
    
 formatDate(dateString) {
    if (dateString !== null) {
	    const date = new Date(dateString);
	    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	    const day = String(date.getDate()).padStart(2, '0');
	    const monthAbbreviation = months[date.getMonth()];
	    const year = date.getFullYear();
	    return `${day} ${monthAbbreviation} ${year}`;
  	}
  	// Handle the case when dateString is null or invalid
  	return '';
    
   },

  },
  mounted() {
  	this.initializeColumns();
    this.renderArtifactsTable();
  }
};
</script>

<style>
.modal-dialog {
  margin-top: -300px;
}
.ant-table-row  {
	text-align: center;
}
.ant-table-thead th span {
	text-align: center;
	width: 100%
}
.ant-table-fixed td span {
    text-align: center;
}

</style>
