<template>


    <div>
        <div class="row card-container">
            <div class="col artifact-card" v-for="artifact in artifacts" :key="artifact.artifactId">
                <img class="artifact-image" :alt="artifact.artifactNumber" :src="`/images/${artifact.imageFile}`" />
                <!-- <img class="artifact-image" :alt="artifact.artifactNumber"
                    :src="`https://source.unsplash.com/collection/928423/230x250`" /> -->
                <div class="artifact-info">
                    <div class="artifact-details">
                        <p> Artifact: <a :href="'#artifact/' +  artifact.artifactId"  target="_blank" class="artifact-link">{{ artifact.imageFile }}</a></p>
                        <p>{{ artifact.cityState }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>
  
<script>

export default {
    components: {
    },
    props: {
        artifacts: Array,
    },

    methods: {

    },
};
</script>
  
<style scoped>
.card-container {
    display: flex;
    column-gap: 15px;
    flex-wrap: wrap;
    margin: 5px;

}

.card {
    max-width: 230px;

}

.artifact-picture-view {
    margin-bottom: 15px;
    /* Separate each row from the content below */
}

.artifact-card {
    max-width: 250px;
    min-width: 250px;
    border-radius: 4px;
    padding: 0px;
    margin-bottom: 25px;
    /* Separate each card */
}

.artifact-image {
	    height: 200px;
	    /* object-fit: contain; */
    border-radius: 4px;
}

.artifact-info {
    margin-top: 0px;
}

.artifact-name {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 2px;
    line-height: 1.2;
}

.artifact-details p {
    margin-top: 0px;
    text-align: left;
}

.artifact-link {
    display: inline;
    margin-top: 5px;
    color: #1890ff;
}
</style>
  