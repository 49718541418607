import http from "../http-common";

class ArtifactService {
  getAllArtifacts(searchDTO) {
    console.log(searchDTO)
    return http.get(`/artifact/artifacts`, {
		params: searchDTO
	});
  }

  get(artifactId) {
  	return http.get(`/artifact/${artifactId}`);
  }

  findByField(matchData) {
  	return http.get(`/artifact?field=${matchData}`);
  }

  addArtifact(data) {
    return http.post("/artifact/addArtifact", data);
  }

  update(data) {
  	return http.post("/artifact/updateArtifact", data);
  }
  
  uploadImage(data,artifactId) {
  	return http.postForm("/artifact/uploadImage/"+artifactId, data);
  }
}

export default new ArtifactService();
