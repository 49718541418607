<template>
  <div class="content">

<table>
	<tr> 
	</tr> 
</table>

<table>

	<tr> 
		<td class="detail_view_column_1"></td>
		<td class="detail_view_column_2"></td>
		<td rowspan="50"><img class="artifact-image" :alt="artifactDetails.imagelFile" :src="`/images/${artifactDetails.imageFile}`" /></td>
	</tr> 




									<tr> 
																			    		<td class="detail_view_column_1"><b> {{ artifactDetails.cityState }} </b></td>
				    					    								<td class="detail_view_column_2">City, State</td>
							</tr>
									<tr> 
																			    		<td class="detail_view_column_1"><b> {{ artifactDetails.givenName }} </b></td>
				    					    								<td class="detail_view_column_2">Given Name</td>
							</tr>
									<tr> 
																			    		<td class="detail_view_column_1"><b> {{ artifactDetails.middleName }} </b></td>
				    					    								<td class="detail_view_column_2">Middle Name</td>
							</tr>
									<tr> 
																			    		<td class="detail_view_column_1"><b> {{ artifactDetails.surname }} </b></td>
				    					    								<td class="detail_view_column_2">Surname</td>
							</tr>
									<tr> 
																			    		<td class="detail_view_column_1"><b> {{ artifactDetails.maidenName }} </b></td>
				    					    								<td class="detail_view_column_2">Maiden Name</td>
							</tr>
									<tr> 
																			    		<td class="detail_view_column_1"><b> {{ artifactDetails.surnameVariation }} </b></td>
				    					    								<td class="detail_view_column_2">Surname Variation</td>
							</tr>
									<tr> 
																			    		<td class="detail_view_column_1"><b> {{ artifactDetails.birthDate }} </b></td>
				    					    								<td class="detail_view_column_2">Birth Date</td>
							</tr>
									<tr> 
																			    		<td class="detail_view_column_1"><b> {{ artifactDetails.deathDate }} </b></td>
				    					    								<td class="detail_view_column_2">Death Date</td>
							</tr>
									<tr> 
																			    		<td class="detail_view_column_1"><b> {{ artifactDetails.photoDate }} </b></td>
				    					    								<td class="detail_view_column_2">Photo Date</td>
							</tr>
									<tr> 
																			    		<td class="detail_view_column_1"><b> {{ artifactDetails.marriageDate }} </b></td>
				    					    								<td class="detail_view_column_2">Marriage Date</td>
							</tr>
									<tr> 
																			    		<td class="detail_view_column_1"><b> {{ artifactDetails.locationFound }} </b></td>
				    					    								<td class="detail_view_column_2">Location Found</td>
							</tr>
									<tr> 
																			    		<td class="detail_view_column_1"><b> {{ artifactDetails.cityPhotographTaken }} </b></td>
				    					    								<td class="detail_view_column_2">City Photograph Taken</td>
							</tr>
									<tr> 
																			    		<td class="detail_view_column_1"><b> {{ artifactDetails.photographerOrStudioMark }} </b></td>
				    					    								<td class="detail_view_column_2">Photographer Or Studio Mark</td>
							</tr>
									<tr> 
																			    		<td class="detail_view_column_1"><b> {{ artifactDetails.imageFile }} </b></td>
				    					    								<td class="detail_view_column_2">ImageFile</td>
							</tr>
			

</table>

    <a-tabs v-model:activeKey="activeKey">
    </a-tabs>

</div>
</template>
<script>

import ArtifactService from "../services/ArtifactService";
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/Inputs/BaseInput";
import NotificationTemplate from "@/pages/Notifications/NotificationTemplate";
import { Card } from "@/components/index";
import { ASelect, ASelectOption, AButton, Table, Pagination, message } from "ant-design-vue";
import { VueAutosuggest } from "vue-autosuggest";
import { UploadOutlined } from '@ant-design/icons-vue';
import Datepicker from 'vuejs-datepicker';


const tableColumns = [];
const tableData = [];
const fileList = ([]);
let file = ({});
const checked = (false);
let yearValue = ('');

export default {
  props: {
    artifactId: {
      type: String,
      required: true
    }
  },
  components: {
		Modal,
		BaseButton,
		BaseInput,
		VueAutosuggest,
		UploadOutlined,
		Datepicker,
  },
  data() {
    return {
      artifactToAdd: {},
      modal2Artifacts: false,
      artifactDetails: null,


      fileList,
      checked,
	  yearValue,

    };
  },
  methods: {

    

	
	handleSwitchChange(lifAnswerId, checked) {
	    this.answers.forEach(function(answer) {
	    	if (answer.questionType == '2') {
		    	if (answer.lifAnswerId == lifAnswerId) {
					answer.answerb = checked;
					if ( checked) {
						answer.answer = "Yes";
					} else {
						answer.answer = "No";
					}
				}
	    	}
		});
	},

	async handleAddSubmitted() {
      this.modal2Artifacts = false;

      const jsonData = JSON.stringify(this.artifactToAdd);
      console.log(jsonData);
      
      const res = await ArtifactService.addArtifact(jsonData);

      if (res.status === 200) {
        this.$notify({
          component: NotificationTemplate,
          icon: "tim-icons icon-bell-55",
          type: "success",
          timeout: 3000,
        });
      }
	},

    async updateArtifact() {

      const jsonData = JSON.stringify(this.artifactDetails);
      const res = await ArtifactService.update(jsonData);

      if (res.status === 200) {
//        this.$notify({
//          component: NotificationTemplate,
//          icon: "tim-icons icon-bell-55",
//          type: "success",
//          timeout: 3000,
//        });

//        this.modalArtifacts = false;
//        this.getAllArtifacts();
      }
    },

    async getArtifactDetails() {
      try {
			let response = await ArtifactService.get(this.artifactId);
			this.artifactDetails = response.data;

			let hasAnswers = 0;

			if (hasAnswers) {			
		        this.answers.forEach(function(answer) {
		            if (answer.answer === "Yes") {
		               temp = true;
		            } else {
		               temp = false;
		            }
		        });
			}


	    
      } catch (error) {
        console.error('Error fetching artifact details:', error);
      }
    },

	formatTime(dateString) {
      if(dateString !== null){
        const date = new Date(dateString);
      return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} EST`;
      }
      // Format the date here as needed, for example:
    },  
    
 formatDate(dateString) {
    if (dateString !== null) {
	    const date = new Date(dateString);
	    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	    const day = String(date.getDate()).padStart(2, '0');
	    const monthAbbreviation = months[date.getMonth()];
	    const year = date.getFullYear();
	    return `${day} ${monthAbbreviation} ${year}`;
  	}
  	// Handle the case when dateString is null or invalid
  	return '';
    
   },

  },
  mounted() {
    this.getArtifactDetails();
  },
  computed: {
  },
  
};
</script>
<style>

.detail_view_input input {
	text-align: center;
}

.detail_view_column_1 {
	width: 250px; 
	text-align: center;
}
.detail_view_column_2 {
	min-width: 250px; 
}
.input {
	text-align: center;
}
#autosuggest__input {
  display: inline-block;
}
.autosuggest__results-container {
	position: relative;
}
.autosuggest__results {
	font-weight: 300;
	margin: 0;
	position: absolute;
	z-index: 10000001;
	border: 1px solid #e0e0e0;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	background: white;
}
.autosuggest__results ul {
	list-style: none;
	padding-left: 0;
	margin: 0;
}
.autosuggest__results .autosuggest__results-item {
	cursor: pointer;
	padding: 5px;
}
#autosuggest ul:nth-child(1) > .autosuggest__results_title {
	border-top: none;
}
.autosuggest__results .autosuggest__results_title {
	color: gray;
	font-size: 11px;
	margin-left: 0;
	padding: 15px 13px 5px;
	border-top: 1px solid lightgray;
}
.autosuggest__results .autosuggest__results-item:active,
.autosuggest__results .autosuggest__results-item:hover,
.autosuggest__results .autosuggest__results-item:focus,
.autosuggest__results
.autosuggest__results-item.autosuggest__results-item--highlighted {
	background-color: #F6F6F6;
}

.artifact-image {
		    max-height: 600px;
	}

.inline-item {
  display: inline-block;
  margin-right: 100px; /* Adjust margin as needed */
}

.switch-container {
	width: 215px; 
    text-align: right;
    margin-right: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}

</style>

